export const getTotalScrollTime = (text: string, speed: number) => {
  const wordsCount = htmlWordCount(text);
  const wordsPerMinute = speed;
  const totalScrollTime = (wordsCount / wordsPerMinute) * 60000; // in millisecond
  return totalScrollTime;
};

export const htmlWordCount = (text: string) => {
  if (text && text.trim()) {
    text = text.replace(/<[^>]*>/g, ' ');
    text = text.replace(/\s+/g, ' ');
    text = text.trim();
    text = text.replace(/Powered by Froala Editor/gi, '');
    return text.split(' ').filter((item) => item.trim()).length;
  }
  return 0;
};
